import { render, staticRenderFns } from "./articles.vue?vue&type=template&id=42127b53&scoped=true&v-if=%24i18n.locale%20%3D%3D%3D%20'ar'"
import script from "./articles.vue?vue&type=script&lang=js"
export * from "./articles.vue?vue&type=script&lang=js"
import style0 from "./articles.vue?vue&type=style&index=0&id=42127b53&prod&lang=css"
import style1 from "./articles.vue?vue&type=style&index=1&id=42127b53&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42127b53",
  null
  
)

export default component.exports